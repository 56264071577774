import React, { useState } from 'react'
import f1 from "../../Images/InterFace/First/1.png"
import f2 from "../../Images/InterFace/First/2.png";
import f3 from "../../Images/InterFace/First/3.png";
import f4 from "../../Images/InterFace/First/4.png";
import f5 from "../../Images/InterFace/First/5.png";
import t1 from "../../Images/InterFace/Third/1.png";
import t2 from "../../Images/InterFace/Third/2.png";
import t3 from "../../Images/InterFace/Third/3.png";
import t4 from "../../Images/InterFace/Third/4.png";
import t5 from "../../Images/InterFace/Third/5.png";
import s1 from "../../Images/InterFace/Second/1.png";
import s2 from "../../Images/InterFace/Second/2.png"
import s3 from "../../Images/InterFace/Second/3.png"
import s4 from "../../Images/InterFace/Second/4.png"
import s5 from "../../Images/InterFace/Second/5.png"
import fo1 from "../../Images/InterFace/Forth/1.png";
import fo2 from "../../Images/InterFace/Forth/2.png";
import fo3 from "../../Images/InterFace/Forth/3.png";
import fo4 from "../../Images/InterFace/Forth/4.png";
import fo5 from "../../Images/InterFace/Forth/5.png";

const images = [
  t1,
  t2,
  t3,
  t4,
  t5,
  f1,
  f2,
  f3,
  f4,
  f5,
  s1,
  s2,
  s3,
  s4,
  s5,
  fo1,
  fo2,
  fo3,
  fo4,
  fo5,
];
const buttonLabels = [
  "EasyOffice Dashboard",
  "Income Tax Software",
  "TDS Return Software",
  "Audit Software",
  "CMA Software",
  "JSON / XML Import",
  "Challan Payment",
  "Return Status",
  "Tax Register",
  "IT Dashboard",
  "Deductions (VIA)",
  "Online Data Tools",
  "Multi-Year Tax Summary",
  "TDS Forms",
  "Online Tools",
  "Audit Data Import",
  "Fixed Asset Chart",
  "CMA Data Entry",
  "My Invoice",
  "Circular / Notification",
];

const InterFace = () => {
  const [currentImage, setCurrentImage] = useState(t1);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleButtonClick = (index) => {
    setCurrentImage(images[index]);
    setActiveIndex(index);
  };

  return (
    <div className="py-20 bg-white">
      <div className="text-center mb-7">
        <p className="font-bold text-4xl text-black">
          Simplifying Your Workflow
        </p>
      </div>
      {/* Desktop View */}
      <div className="hidden sm:flex justify-center px-16">
        <div className="flex items-center w-full max-w-7xl">
          {/* Buttons Section - Left */}
          <div className="space-y-1 w-[40%] mr-3">
            {images.slice(0, 10).map((_, index) => (
              <button
                key={index}
                className={`px-5 py-1 w-full text-center text-lg border-[#2196f3] duration-200  ${
                  activeIndex === index ? "text-white" : "text-black"
                } ${activeIndex === index ? "font-bold" : "font-medium"} ${
                  activeIndex === index ? "bg-[#2196f3]" : "bg-[#ebf3fd]"
                } ${activeIndex === index ? "border-none" : "border-b"}`}
                onClick={() => handleButtonClick(index)}
              >
                {buttonLabels[index]}
              </button>
            ))}
          </div>

          {/* Image Section */}
          <div className="flex sm:h-[470px] sm:w-[1810px] justify-center items-center rounded-xl">
            <img
              src={currentImage}
              alt="Displayed"
              className=" w-[100%] h-full rounded-xl shadow-xl"
            />
          </div>

          {/* Buttons Section - Right */}
          <div className="space-y-1 w-[40%] ml-3">
            {images.slice(10, 20).map((_, index) => (
              <button
                key={index + 10}
                className={`px-5 py-1 w-full text-center text-lg border-[#2196f3] duration-200 ${
                  activeIndex === index + 10 ? "text-white" : "text-black"
                } ${activeIndex === index + 10 ? "font-bold" : "font-medium"} ${
                  activeIndex === index + 10 ? "bg-[#2196f3]" : "bg-[#ebf3fd]"
                } ${
                  activeIndex === index + 10 ? "border-none" : "border-b-[1px]"
                }`}
                onClick={() => handleButtonClick(index + 10)}
              >
                {buttonLabels[index + 10]}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="block sm:hidden px-3">
        <div className="flex flex-col">
          {/* Buttons Section */}
          <div className="sm:space-y-2 grid grid-cols-2 gap-2">
            {images.map((_, index) => (
              <button
                key={index}
                className={`px-5 py-1 w-full font-bold text-left ${
                  activeIndex === index ? "bg-[#2196f3]" : "bg-[#ebf3fd]"
                } ${activeIndex === index ? "text-white" : "text-black"}
                `}
                onClick={() => handleButtonClick(index)}
              >
                {buttonLabels[index]}
              </button>
            ))}
          </div>

          {/* Image Section */}
          <div className="flex-1 flex justify-center items-center mt-4">
            <img
              src={currentImage}
              alt="Displayed"
              className="w-full max-w-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterFace;

// import EasyOfficeImage from "../../Images/ProductBG/es5.jpg";
// import EasyGSTImage from "../../Images/ProductBG/g1.jpg";
// import EasyACCImage from "../../Images/ProductBG/as1.jpg";

let Products = [
  {
    name: "General",
    title: "General",
    faq: [
      {
        question: "Q1. Which Software is Best for CA & Tax Professionals?",
        answer:
          "EasyOffice is a Complete Taxation software that offers comprehensive solutions for Income Tax Returns, TDS/TCS return filing, Audit e-Filing, Balance Sheet Preparation, CMA Data reports and Office Management utilities. This software helps individuals and businesses to file their tax returns quickly and easily. It offers various additional features for office management and has the ability to import XML/JSON files, making it one of the best taxation software in India among tax professionals. ",
      },
      {
        question: "Q2. Any user-friendly software solution for return filing?",
        answer:
          "Yes, Easyoffice software is designed to be user-friendly and easy to operate. It has a simple and intuitive interface that guides users through the tax filing process, making it easy for both beginners and experienced users.",
      },
      {
        question:
          "Q3. Does Easyoffice software provide online support and assistance?",
        answer:
          "Yes, Easyoffice provides online support and assistance to its users. Users can access a comprehensive knowledge video tutorial and FAQs to help them with their tax filing needs. Users can also contact EasyOFFICE’s customer support team via email, phone, or chat for any queries or issues.",
      },
      {
        question:
          "Q4. Can data be imported from other Taxation software into EasyOffice Income Tax Software?",
        answer:
          "Yes, EasyOffice Income Tax Module includes features for importing data from other Taxation software using JSON / XML files. This makes it easy to transfer Return data from other software into EasyTAX for use in tax calculations and reporting.",
      },
      {
        question:
          "Q5. What are the key features of EasyTax Income Tax Software?",
        answer:
          "Yes, you can easily and quickly prepare and file Income Tax Returns (ITR) 1 to 7 directly through EasyTax software or copy the JSON and upload it on the ITD Portal.",
      },
    ],
  },
  {
    name: "EasyTAX",
    title: "EasyTAX",
    faq: [
      {
        question:
          "Q1. Which is the Best Income Tax return filing software for Tax Professionals?",
        answer:
          "EasyOFFICE is considered as the first choice of tax Professionals because of its user-friendly interface, advanced features, and comprehensive tax computation capabilities. It simplifies the process of filing income tax returns and helps tax consultants & CA’s to manage their client’s tax management efficiently.",
      },
      {
        question:
          "Q2. What are the important factors to consider when choosing tax filing software for CA’s & Tax consultants?",
        answer:
          "When choosing tax filing software several factors should be considered. The Most important are Accuracy of Tax calculation, Timely updation, and Price, the software should have a user-friendly interface & Multi-Angle Analysis Reports. EasyOFFICE's EasyTax is designed to be user-friendly with accuracy, making it the best Suitable preference for tax Professionals of all levels.",
      },
      {
        question:
          "Q3. Can data be imported from other Taxation software into EasyOffice Income Tax Software?",
        answer:
          "Yes, EasyOffice Income Tax Module includes features for importing data from other Taxation software using JSON / XML files. This makes it easy to transfer Return data from other software into EasyTAX for use in tax calculations and reporting.",
      },
      {
        question:
          "Q4. Can we use the EasyOffice Income Tax software free trial version?",
        answer:
          "Yes, EasyOffice software offers a free trial version for interested users. The trial version provides access to the software’s operations for a limited period, allowing users to test its features and functionalities. The EasyTAX trial version can be downloaded from our website.",
      },
      {
        question:
          "Q5. What are the key features of EasyTax- Income Tax Software?",
        answer:
          "EasyTax offers a wide range of features including computation of income and tax, tax liability summary, e-filing of returns, deduction under chapter VIA, self-assessment tax, advance tax, interest calculation under sections 234A, 234B, 234C, 234F & more features. It also offers easy import/export of data, user-friendly interfaces and advanced online tools, making it a preferred choice for tax professionals and consultants.",
      },
      {
        question: "Q6. Which is Best Software to file ITR? ",
        answer:
          "There are various Tax Software available in the Market. Easyoffice Income Tax Software is one of the Preferred & Trusted choice of Chartered Accountants & Tax Professionals for ITR efiling.",
      },
    ],
  },
  {
    name: "EasyAUDIT",
    title: "EasyAUDIT",
    faq: [
      {
        question: "Q1 Which software is Best for Audit e-filing?",
        answer:
          "EasyOFFICE’s EasyAudit software is a tool designed for chartered accountants and auditors to prepare and audit e-filing reports such as 3CA-3CB, 3CB-3CD. It streamlines the Audit data management process by incorporating various audit forms, account statements and importing trial balances from Excel and Tally. Using EasyAudit can save time, effort, and ensure compliance with regulatory requirements",
      },
      {
        question:
          "Q2.  Is there any software with both Audit Management and balance sheet preparation facilities?",
        answer:
          "EasyAudit software is an all-in-one solution for Audit Management and Balance Sheet Preparation facilities. The software incorporates various audit forms, prepares account statements, and imports trial balances from Excel and Tally. This comprehensive solution makes EasyAudit software an ideal choice for chartered accountants and auditors who require both audit management and balance sheet preparation capabilities.",
      },
      {
        question:
          "Q3. What types of audit forms does EasyAudit software support?",
        answer:
          "EasyAudit software supports various audit forms, including 3CA, 3CB, 3CD, 29B, 3CEB and 10B & others. This comprehensive software support for different audit forms ensures that users can prepare reports that comply with regulatory requirements.",
      },
      {
        question: "Q4. Can I try EasyAudit software before purchasing it?",
        answer:
          "Yes, EasyAudit software provides a free trial period for users to try out the software before purchasing it. This trial period allows users to evaluate the software's features and determine if it is the right fit for their business.",
      },
    ],
  },
  {
    name: "EasyTDS",
    title: "EasyTDS",
    faq: [
      {
        question:
          "Q1. What features should I look for in TDS and TCS return filing software?",
        answer:
          "When choosing TDS and TCS return filing software, it's important to look for features such as auto data validation, e-filing and correction of returns, interest calculation, and consolidated Analysis reports. EasyTDS software offers all of these features at an affordable price, making it a top choice for businesses and Tax professionals.",
      },
      {
        question:
          "Q2. Which is the best software to generate revised TDS returns if needed, and how easy is the process?",
        answer:
          "Yes, EasyOFFICE’s EasyTDS software has an eTDS correction module that allows users to generate revised TDS returns if needed. The process is easy and can be done through the software's user-friendly interface.",
      },
      {
        question:
          "Q3. Can I import data from other sources into EasyTDS software, such as Excel or text files?",
        answer:
          "Yes, EasyTDS software allows users to import data from Excel sheets, text files, consolidate (.tds) files.",
      },
      {
        question:
          "Q4. Is there any trial version of the software TDS software?",
        answer:
          "Yes, EasyOFFICE’s EasyTDS provides a free trial version of its software. The trial version is fully functional and allows you to test all the features of the software. To get access to the trial version, you can visit the website and request a demo or download the trial version directly from the website.",
      },
      {
        question:
          "Q5. 	Which are cost effective options for TDS and TCS return software?",
        answer:
          "There are several TDS and TCS return software providers in the market, but EasyOFFICE’s TDS module stands out with its feature-rich software that comes at an affordable price. EasyTDS also offers free demo trial, allowing users to try out the software before making a purchase.",
      },
    ],
  },
  {
    name: "EasyCMA",
    title: "EasyCMA",
    faq: [
      {
        question: "Q1. What is CMA software?",
        answer:
          "CMA software is designed to assist Chartered Management Accountants & CA’s in managing financial operations, including budgeting, forecasting, financial reporting, and compliance with regulatory standards. It provides advanced tools for financial analysis and decision-making.",
      },
      {
        question: "Q2. Is there a trial version of EasyCMA available?",
        answer:
          "Yes, EasyCMA offers a trial version. This allows you to explore its features and see how it fits your needs before committing.",
      },
      {
        question: "Q3. What should I do if I encounter a problem with EasyCMA?",
        answer:
          "If you encounter any issues, contact EasyCMA’s support team for assistance. Provide details about the problem and any error messages to help resolve the issue efficiently.",
      },
    ],
  },
];

export default Products;


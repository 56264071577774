import React from "react";
import "./style.css";
import easyTaxIcon from "../../Images/Products/easyTax.webp";
import easyAuditIcon from "../../Images/Products/easyAudit.webp";
import easyTdsIcon from "../../Images/Products/easyTds.webp";
import easyCmaIcon from "../../Images/Products/EasyCma.webp";

const InfoGraph = React.memo(() => {
  const modules = [
    {
      id: 3,
      imgSrc: easyAuditIcon,
      bgColor: "#c2efff",
      title: "Audit E-filling & Balance Sheet Preparation",
      features: [
        "Form 3CA-3CD/3CB-3CD with annexure",
        "Tax Audit & Company Audit Reports",
      ],
    },
    {
      id: 4,
      imgSrc: easyCmaIcon,
      bgColor: "#f3ffc5",
      title: "CMA Data preparation",
      features: ["Multi year CMA data", "Many Financial tool"],
    },
    {
      id: 1,
      imgSrc: easyTaxIcon,
      bgColor: "#c2efff",
      title: "Income Tax Software",
      features: ["Quick ITR Upload", "Income Tax Computation"],
    },
    {
      id: 2,
      imgSrc: easyTdsIcon,
      bgColor: "#f3ffc5",
      title: "TDS & TCS software",
      features: ["Generate TDS & TCS Returns", "Correction Returns"],
    },
  ];

  return (
    <section className="hidden sm:block my-10 mb-28">
      <div className="container12">
        <div className="container__title">
          <h1 className="container__titleMainText">
            EasyOffice Software Modules
          </h1>
        </div>
        <div className="container__circle">
          <div className="container__mainFeat flex flex-col items-center w-full">
            <img src="./Images//easyOffice.webp" className="mb-5 w-40" alt="" />
            {/* <h3 className="container__mainFeatMainText">EasyOFFICE Software</h3> */}
            <p className="container__mainFeatSubText"></p>
          </div>

          {modules.map((item, index) => (
            <div
              className={`container__subFeat ${
                item.id === 1 || item.id === 2
                  ? ""
                  : "container__subFeat--right"
              }`}
              key={index}
            >
              <div
                className="container__subFeatImgContent"
                style={{ backgroundColor: item.bgColor }}
              >
                <img
                  src={item.imgSrc}
                  style={{ width: "150px" }}
                  alt={item.title}
                />
              </div>
              <div className="container__subFeatContent bg-orange-300 p-5">
                <h3 className="container__subFeatMainText">{item.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
});

export default InfoGraph;

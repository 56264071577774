import React from "react";
import { Link } from "react-scroll";
import SocialNavBar from "../../Components/ui/SocialNavBar";

const footerData = {
  "Usefull link": [
    { name: "About", url: "https://www.easyofficesoftware.com/about" },
    { name: "Blog", url: "https://www.easyofficesoftware.com/blog" },
    {
      name: "Product Catalogue",
      url: "https://www.easyofficesoftware.com/product_catalogue",
    },
    {
      name: "Bank Details",
      url: "https://www.easyofficesoftware.com/bank_details",
    },
  ],
  "Our Software": [
    {
      name: "EasyOFFICE",
      url: "https://www.easyofficesoftware.com/easyofficesoftware",
    },
    { name: "EasyGST", url: "https://www.easyofficesoftware.com/easygst" },
    { name: "EasyACC", url: "https://www.easyofficesoftware.com/easyacc" },
    {
      name: "EasyTAX",
      url: "https://www.easyofficesoftware.com/incometaxsoftware",
    },
    {
      name: "EasyAUDIT",
      url: "https://www.easyofficesoftware.com/auditsoftware",
    },
    { name: "EasyTDS", url: "https://www.easyofficesoftware.com/tdssoftware" },
    { name: "EasyCMA", url: "https://www.easyofficesoftware.com/cmasoftware" },
  ],
  "EASYOFFICE": [
    {
      name: "Income Tax Software",
      url: "https://www.easyofficesoftware.com/easyofficesoftware",
    },
    {
      name: "TDS Software",
      url: "https://www.easyofficesoftware.com/tdssoftware",
    },
    {
      name: "TCS Software",
      url: "https://www.easyofficesoftware.com/tdssoftware",
    },
    {
      name: "Audit efiling Software",
      url: "https://www.easyofficesoftware.com/auditsoftware",
    },
    {
      name: "Balance Sheet Software",
      url: "https://www.easyofficesoftware.com/auditsoftware",
    },
    {
      name: "CMA Data Software",
      url: "https://www.easyofficesoftware.com/cmasoftware",
    },
    {
      name: "Office Management Utilities",
      url: "https://www.easyofficesoftware.com/easyofficesoftware",
    },
    {
      name: "Software for Tax Professionals",
      url: "https://www.easyofficesoftware.com/easyofficesoftware",
    },
  ],
  "INCOME TAX": [
    {
      name: "Income Tax Software",
      url: "https://www.easyofficesoftware.com/incometaxsoftware",
    },
    {
      name: "Computation of Income",
      url: "https://www.easyofficesoftware.com/incometaxsoftware",
    },
    {
      name: "AIS, TIS, 26AS Direct Import",
      url: "https://www.easyofficesoftware.com/incometaxsoftware#importFacility",
    },
    {
      name: "JSON Import",
      url: "https://www.easyofficesoftware.com/incometaxsoftware#importFacility",
    },
    {
      name: "Challan e-Payment",
      url: "https://www.easyofficesoftware.com/incometaxsoftware#importFacility",
    },
    {
      name: "Interest Calculation",
      url: "https://www.easyofficesoftware.com/incometaxsoftware#importFacility",
    },
    {
      name: "Arrear Relief calculation",
      url: "https://www.easyofficesoftware.com/incometaxsoftware#importFacility",
    },
    {
      name: "Best Software for ITR filing",
      url: "https://www.easyofficesoftware.com/incometaxsoftware",
    },
  ],
  "TDS & TCS": [
    {
      name: "Prepares TDS Return",
      url: "https://www.easyofficesoftware.com/tdssoftware",
    },
    {
      name: "Prepares TCS Return",
      url: "https://www.easyofficesoftware.com/tdssoftware",
    },
    {
      name: "24Q, 26Q, 27Q",
      url: "https://www.easyofficesoftware.com/tdssoftware#trial_gst",
    },
    {
      name: "27EQ, 26QB",
      url: "https://www.easyofficesoftware.com/tdssoftware#trial_gst",
    },
    {
      name: "Excel Import - Export",
      url: "https://www.easyofficesoftware.com/tdssoftware#trial_gst",
    },
    {
      name: "Bulk PAN Verification",
      url: "https://www.easyofficesoftware.com/tdssoftware#trial_gst",
    },
    {
      name: "Correction Return",
      url: "https://www.easyofficesoftware.com/tdssoftware#trial_gst",
    },
    {
      name: "Generate TDS Certificates",
      url: "https://www.easyofficesoftware.com/tdssoftware#trial_gst",
    },
    {
      name: "Best Software for TDS Return",
      url: "https://www.easyofficesoftware.com/tdssoftware",
    },
  ],
  "AUDIT": [
    {
      name: "E-Audit Report",
      url: "https://www.easyofficesoftware.com/auditsoftware",
    },
    {
      name: "Data Import facility",
      url: "https://www.easyofficesoftware.com/auditsoftware",
    },
    {
      name: "Form 3CEB/29B/16B/10B/10BB",
      url: "https://www.easyofficesoftware.com/auditsoftware",
    },
    {
      name: "XML/ JSON for Audit Forms",
      url: "https://www.easyofficesoftware.com/auditsoftware",
    },
    {
      name: "Form 3CD Answers",
      url: "https://www.easyofficesoftware.com/auditsoftware",
    },
    {
      name: "Balance Sheet",
      url: "https://www.easyofficesoftware.com/auditsoftware",
    },
    {
      name: "Best Software for Audit",
      url: "https://www.easyofficesoftware.com/auditsoftware",
    },
  ],
};

const Footer = React.memo(() => {
  return (
    <>
      <footer class="bg-gray-600" aria-labelledby="footer-heading">
        <h2 id="footer-heading" class="sr-only">
          Footer
        </h2>
        <div class="mx-auto max-w-7xl px-6 pb-8 pt-10 sm:pt-24 lg:px-8 lg:pt-20">
          <div class="xl:grid xl:grid-cols-3 xl:gap-8">
            <div class="space-y-5">
              <h2 className="font-bold text-3xl text-white">
                Electrocom Software Private Limited
              </h2>
              <div className="flex flex-col">
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="white"
                    class="bi bi-telephone-fill"
                    viewBox="0 0 16 16"
                    className="mt-[6px] mr-3"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                    />
                  </svg>
                  <a
                    href="tel:07927562400"
                    className="text-white font-semibold text-lg cursor-pointer"
                  >
                    079-27562400 (Multi-line)
                  </a>{" "}
                </div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="white"
                    class="bi bi-telephone-fill"
                    viewBox="0 0 16 16"
                    className="mt-[6px] mr-3"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                    />
                  </svg>
                  <a
                    href="tel:07935014600"
                    className="text-white font-semibold text-lg cursor-pointer"
                  >
                    079-35014600 (Multi-line)
                  </a>
                </div>
              </div>
              <div className="flex items-center mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="white"
                  class="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                </svg>
                <p className="mb-0 ml-3">
                  <a
                    href="https://wa.me/+917046062400?text="
                    target="_blank"
                    rel="noreferrer"
                    className="text-white font-semibold text-lg"
                  >
                    +91-70460 62400
                  </a>{" "}
                </p>
              </div>
              <div className="flex flex-col mt-2">
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="white"
                    class="bi bi-envelope-fill"
                    viewBox="0 0 16 16"
                    className="mt-[6px] mr-3"
                  >
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                  </svg>
                  <a
                    href="mailto:info@easyofficesoftware.com"
                    className="text-white font-semibold text-lg"
                  >
                    info@easyofficesoftware.com
                  </a>{" "}
                </div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="white"
                    class="bi bi-envelope-fill"
                    viewBox="0 0 16 16"
                    className="mt-[6px] mr-3"
                  >
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
                  </svg>
                  <a
                    href="mailto:info@easyofficesoftware.com"
                    className="text-white font-semibold text-lg"
                  >
                    info@electrocom.in
                  </a>{" "}
                  <br />{" "}
                </div>
              </div>
              <div className="flex flex-col mt-2">
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="white"
                    class="bi bi-globe mt-2 mr-3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 0 0 1-.312 2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z" />
                  </svg>
                  <a
                    href="https://www.easyofficesoftware.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white font-semibold text-lg"
                  >
                    easyofficesoftware.com
                  </a>{" "}
                </div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="white"
                    class="bi bi-globe mt-2 mr-3"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 0 0 1-.312 2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z" />
                  </svg>
                  <a
                    href="https://www.electrocom.in/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white font-semibold text-lg"
                  >
                    electrocom.in
                  </a>{" "}
                </div>
                <br />{" "}
              </div>
              <SocialNavBar Color={"bg-black mb-8"} />

              <div>
                <p className="text-2xl mb-3 mt-4 sm:mb-0 sm:mt-0 font-bold text-white underline">
                  Office Time
                </p>
                <div>
                  <p className="text-white mt-4 leading-[2rem] font-semibold">
                    10:15AM - 7PM (Mon-Fri) <br /> 10:30AM - 5PM (Sat)
                  </p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-4 sm:mt-0 mt-16 col-span-2 sm:gap-5">
              <div>
                <p className="text-2xl mb-3 mt-4 sm:mb-0 sm:mt-0 font-bold text-white underline">
                  Contact Us
                </p>
                <div className="mt-3">
                  <p className="text-base leading-8 text-white hover:text-gray-100">
                    505, Sukhsagar Complex, <br /> Nr. Fortune Landmark <br />
                    Usmanpura Cross Road, <br />
                    Ashram Road, Ahmedabad - 380013
                  </p>
                </div>
              </div>
              <div>
                <p className="text-2xl mb-3 mt-4 sm:mb-0 sm:mt-0 font-bold text-white underline">
                  Support
                </p>
                <div className="">
                  <div>
                    {" "}
                    <ul className="mt-3">
                      <li>
                        <a
                          href="https://www.easyofficesoftware.com/public/assets_front/software/LiveCare.exe"
                          target="_blank"
                          rel="noreferrer"
                          className="text-base leading-6 text-white hover:text-gray-100"
                        >
                          Live Care
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    {" "}
                    <ul className="mt-3">
                      <li>
                        <a
                          href="https://www.easyofficesoftware.com/video"
                          target="_blank"
                          rel="noreferrer"
                          className="text-base leading-6 text-white hover:text-gray-100"
                        >
                          Software Video
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    {" "}
                    <ul className="mt-3">
                      <li>
                        <Link to="FAQ">
                          <p className="text-base mb-0 leading-6 text-white hover:text-gray-100 cursor-pointer">
                            FAQ's
                          </p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div>
                    {" "}
                    <ul className="mt-3">
                      <li>
                        <a
                          href="https://www.easyofficesoftware.com/product_demo"
                          target="_blank"
                          rel="noreferrer"
                          className="text-base leading-6 text-white hover:text-gray-100"
                        >
                          Demo
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {Object.entries(footerData).map(([category, items]) => (
                <div key={category} className="">
                  <p className="text-2xl  mb-3 mt-4 sm:mb-0 sm:mt-0 font-bold text-white underline">
                    {category}
                  </p>
                  <div className="">
                    {items.map((item) => (
                      <div key={item.id}>
                        {" "}
                        <ul className="mt-3">
                          <li>
                            <a
                              href={item.url}
                              target="_blank"
                              rel="noreferrer"
                              className="text-base leading-6 text-white hover:text-gray-100"
                            >
                              {item.name}
                            </a>
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div class="mt-16 border-t border-gray-200/10 pt-8 sm:mt-20 lg:mt-24">
            <p class="text-xs leading-5 text-black">
              &copy; 2024 Electrocom Software Private Limited, Inc. All rights
              reserved.
            </p>
          </div>
        </div>
      </footer>
      <div className="sm:hidden flex bg-gray-600 fixed w-full bottom-0 py-2">
        <div className="flex justify-center w-full">
          <a
            href="https://www.easyofficesoftware.com/product/2"
            target="_blank"
            rel="noreferrer"
          >
            <button className="px-3 py-2 bg-gray-50 shadow-sm shadow-white hover:shadow-none font-bold rounded-lg border border-black">
              Buy Now
            </button>
          </a>
          <a
            href="https://www.easyofficesoftware.com/public/demo/demo/EasyOfficeDemoSetup.exe"
            target="_blank"
            rel="noreferrer"
          >
            <button className="px-3 py-2 bg-gray-50 shadow-sm shadow-white hover:shadow-none font-bold rounded-lg border border-black ml-3">
              Demo
            </button>
          </a>
          <a href="tel:07927562400">
            <button className="px-3 py-2 bg-gray-50 shadow-sm shadow-white hover:shadow-none font-bold rounded-lg border border-black ml-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-telephone-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                />
              </svg>
            </button>
          </a>
        </div>
      </div>
    </>
  );
});

export default Footer;

import t1 from "../../../../Images/ModuleProduct/t1.png";
import t2 from "../../../../Images/ModuleProduct/t2.png";
import t3 from "../../../../Images/ModuleProduct/t3.png";
import t4 from "../../../../Images/ModuleProduct/t4.png";
import t5 from "../../../../Images/ModuleProduct/t5.png";
import cma1 from "../../../../Images/ModuleProduct/cma/c1.webp";
import cma2 from "../../../../Images/ModuleProduct/cma/c2.webp";
import cma3 from "../../../../Images/ModuleProduct/cma/c3.webp";
import cma4 from "../../../../Images/ModuleProduct/cma/c4.webp";

const module4Data = [
  {
    name: "CMA Report",
    back: cma1,
    img: [t1, t2, t3, t4, t5],
    text: [
      "Multi year CMA data report",
      "Multiple Formats of Report",
      "Auto calculation of projected values",
      "Both Percentage wise or Amount wise Calculation",
      "Auto Generation of Report from Operating & Balance Sheet Statement",
    ],
  },
  {
    name: "Statements of Reports",
    back: cma2,
    img: [t1, t2, t3, t4, t5],
    text: [
      "Operating & Balance Sheet Statement",
      "Comparative Statement",
      "Maximum Permissible Bank Finance (MPBF)",
      "Fund flow & Cash flow Statement",
      "Ratio Statements",
    ],
  },
  {
    name: "Financial Tools",
    back: cma3,
    img: [t1, t2, t3, t4, t5],
    text: [
      "EMI & Loan Amount",
      "Interest Rate Calculator",
      "Cash Flow (IRR / MIR) & Net Present value Calculator",
      "Depriciation (WDV / SLM)",
      "Investment Value",
    ],
  },
  {
    name: "Office Management Utilities",
    back: cma4,
    img: [t1, t2, t3, t4, t5],
    text: [
      "Invoice Management",
      "Mass Email Facility",
      "Attendance Register",
      "Document Manager",
      "PDF Signer",
    ],
  },
  
];

export default module4Data;

import React, { useState } from "react";

let Item = React.memo(
  ({ faq }) => {

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleQuestion = (index) => {
      if (index === activeIndex) {
        setActiveIndex(null);
      } else {
        setActiveIndex(index);
      }
    };

    return (
      <div className="item pb-12" >
        <div className="relative isolate overflow-hidden bg-white p-6  lg:overflow-visible lg:px-0">
          <div className="absolute inset-0 -z-10 overflow-hidden">
            <svg
              className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                  width="200"
                  height="200"
                  x="50%"
                  y="-1"
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y="-1" className="overflow-visible fill-gray-50">
                <path
                  d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                  strokeWidth="0"
                />
              </svg>
              <rect
                width="100%"
                height="100%"
                strokeWidth="0"
                fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
              />
            </svg>
          </div>
          <div className="mx-auto grid grid-cols-1 gap-x-4 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
            <div className="lg:col-span-4 lg:col-start-1 lg:row-start-1 sm:pl-14 lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:pr-8">
              <div className="lg:pr-4 col-span-2 flex flex-col">

                    <div className="grid grid-cols-1">
                      {faq.map((q, index) => (
                        <div
                          key={index}
                          className="faq-item cursor-pointer sm:px-10"
                          onClick={() => toggleQuestion(index)}
                        >
                          <div className="flex items-center">
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className={`h-6 w-6 mr-2 transition-transform ${
                                activeIndex === index ? "rotate-45" : ""
                              }`}
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M9 5l7 7-7 7"
                              />
                            </svg>
                            <div
                              className={`faq-question p-3 text-xl font-bold rounded-lg bg-transparent`}
                            >
                              {q.question}
                            </div>
                          </div>

                          {activeIndex === index && (
                            <div className="faq-answer font-medium text-lg p-3 mt-1 px-12">
                              {q.answer}
                            </div>
                          )}
                          <hr className="w-full h-2" />
                        </div>
                      ))}
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default Item;

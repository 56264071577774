import React,{memo} from "react";
import Card from "./card";
import cardData from "./data";
import Slidebard from "../../Components/ui/Slidebard";

const MyComponent = React.memo(() => {
  return (
    <div className="bg-white w-full sm:mt-0 p-3 sm:p-5 overflow-hidden flex justify-center">
      <div className="w-full max-w-7xl">
        <p className="text-center text-black text-4xl font-bold">
          Our Areas of Expertise{" "}
        </p>
        <Slidebard
          MainContent={cardData.map((card, index) => (
            <div key={index}>
              <Card
                title={card.title}
                imageUrl={card.imageUrl}
                content={card.content}
              />
            </div>
          ))}
        />
      </div>
    </div>
  );
});

export default memo(MyComponent);

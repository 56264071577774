import React from 'react'

const AboutCard = React.memo(({ icon, title, description }) => {
  return (
    <div className="bg-white p-7 flex flex-col w-full h-[310px] border border-black shadow-2xl relative  max-sm:mb-5">
      <div>{icon}</div>
      <div className="text-xl font-semibold">{title}</div>
      <div className="text-base my-4">{description}</div>
    </div>
  );
});

export default AboutCard;

import React from 'react'
import GST from '../../Images/Facility/GST.svg'
import TDS from '../../Images/Facility/TDS.png'
import Audit from '../../Images/Facility/Audit.jpg'
import CMA from '../../Images/Facility/CMA.svg'

const Facility = () => {
  return (
    <>
      <div className="flex justify-center sm:mb-24">
        <div className="w-full max-w-7xl space-y-4">
          <div className="flex justify-center mt-14">
            <p className="py-5 mb-0 text-5xl text-center text-black font-extrabold">
              EasyOFFICE - A Complete Taxation Software
            </p>
          </div>
          {/* Right Images */}
          <div className="w-full bg-white py-5 px-5 flex sm:flex-row flex-col-reverse">
            <div className="sm:w-[75%] sm:pr-4 mt-5 sm:mt-0">
              <p className="mb-0 text-4xl sm:text-5xl text-[#2196f3] font-semibold">
                Income Tax Software -{" "}
                <span className="text-black">EasyTAX</span>
              </p>
              <p className="mb-0 mt-5 text-3xl text-black">
                File your Income-Tax Returns Quickly with Ease & Accuracy
              </p>
              <div className="mt-5 space-y-2">
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Computation of Income & Tax
                  </p>
                </div>
                <div className="mt-2 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Auto Generation of Income Statement
                  </p>
                </div>
                <div className="mt-2 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    ITR e-filing with Direct Upload (ITR1-7/ITR-U)
                  </p>
                </div>
                <div className="mt-2 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Pre-validation facility to trace errors
                  </p>
                </div>
                <div className="mt-2 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    JSON/Excel Import facility
                  </p>
                </div>
                <div className="mt-2 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Auto Set off & carry forward of losses
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:w-[25%] rounded-xl p-10 shadow-xl">
              <img src={GST} alt="GST Image" />
            </div>
          </div>
          {/* Left Images */}
          <div className="w-full bg-white py-5 px-5 flex sm:flex-row flex-col">
            <div className="sm:w-[35%] rounded-xl shadow-xl flex justify-center items-center">
              <img src={TDS} alt="GST Image" className="w-[500px]" />
            </div>
            <div className="sm:w-[75%] sm:pl-4 mt-5 sm:mt-0">
              <p className="mb-0 text-4xl sm:text-5xl text-[#2196f3] font-semibold">
                TDS & TCS Software - <span className="text-black">EasyTDS</span>
              </p>
              <p className="mb-0 mt-5 text-3xl text-black">
                Simplify the process of TDS filing
              </p>
              <div className="mt-5 space-y-2">
                <div className="mt-2 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Prepare eTDS & eTCS Return
                  </p>
                </div>
                <div className="mt-2 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    TDS / TCS Certificates (16, 16A, 27D)
                  </p>
                </div>
                <div className="mt-2 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Challan Verification & E-payment
                  </p>
                </div>
                <div className="mt-2 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    TDS Revised Return Correction Module
                  </p>
                </div>
                <div className="mt-2 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Import and Export Facility
                  </p>
                </div>
                <div className="mt-2 flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Reports and Analysis
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Right Images */}
          <div className="w-full bg-white py-5 px-5 flex sm:flex-row flex-col-reverse">
            <div className="sm:w-[78%] mt-5 sm:mt-0">
              <p className="mb-0 text-4xl sm:text-5xl text-[#2196f3] font-semibold">
                Audit e-filing & Balance Sheet -{" "}
                <span className="text-black">EasyAUDIT</span>
              </p>
              <p className="mb-0 mt-5 text-3xl text-black">
                Experience the ease of Audit e-filing
              </p>
              <div className="mt-5 space-y-2">
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Company Audit, Tax Audit & Trust Audit Reports
                  </p>
                </div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    3CA, 3CB, 3CD with annexure
                  </p>
                </div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Account Statements as per Schedule III
                  </p>
                </div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">Trial Balance Entry</p>
                </div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Data Import facility
                  </p>
                </div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Direct upload of Audit e-Reports
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:w-[25%] rounded-xl flex justify-center items-center shadow-xl">
              <img src={Audit} alt="GST Image" />
            </div>
          </div>
          {/* Left Images */}
          <div className="w-full bg-white py-5 px-5 flex sm:flex-row flex-col">
            <div className="sm:w-[25%] rounded-xl flex justify-center items-center p-10 shadow-xl">
              <img src={CMA} alt="GST Image" />
            </div>
            <div className="sm:w-[75%] sm:pl-4 mt-5 sm:mt-0">
              <p className="mb-0 text-4xl sm:text-5xl text-[#2196f3] font-semibold">
                CMA Data Preparation -{" "}
                <span className="text-black">EasyCMA</span>
              </p>
              <p className="mb-0 mt-5 text-3xl text-black">
                Streamlining CMA Data Preparation
              </p>
              <div className="mt-5 space-y-2">
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Multi-Year CMA Data Preparation
                  </p>
                </div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">MPBF Calculations</p>
                </div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Preparation of depreciation chart
                  </p>
                </div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Present value & Maturity value calculations
                  </p>
                </div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Interest calculation
                  </p>
                </div>
                <div className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-caret-right-fill"
                    viewBox="0 0 16 16"
                    className="w-[22px] h-[22px] mt-1 mr-2"
                  >
                    <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                  </svg>
                  <p className="mb-0 text-xl text-black">
                    Multiple formats of report
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Facility;
import React, { useState } from "react";
import Item from "./mainItem";
import products from "./ProductData";
import FAQContent from "../../Components/ui/FAQContent";

let NFAQ = React.memo(() => {
  let [selectedProduct, setSelectedProduct] = useState("General");

  return (
    <section id="FAQ" className="pt-20 flex justify-center bg-white">
      <div className="w-full max-w-7xl">
        <div className="text-center mb-5 sm:mb-10">
          <p className="text-5xl text-black font-bold">FAQ's </p>
        </div>

        <FAQContent
          data={products}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          AddContent={
            selectedProduct && (
              <div className="list">
                {products
                  .filter((product) => product.name === selectedProduct)
                  .map((product) => (
                    <Item
                      key={product.name}
                      faq={product.faq}
                    />
                  ))}
              </div>
            )
          }
        />
      </div>
    </section>
  );
});

export default NFAQ;

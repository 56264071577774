import React, { useState } from "react";
import Item from "./mainItem";
import products from "./ProductData";
import ContentSlider from "../../Components/ui/ContentSlider";

let MainProducts = React.memo(() => {
  let [selectedProduct, setSelectedProduct] = useState("EasyOFFICE");

  return (
    <section className="pt-20 flex justify-center bg-white">
      <div className="w-full max-w-7xl">
        <div className="text-center mb-5 sm:mb-10">
          <p className="text-4xl text-black font-bold">
            Our Software Products{" "}
          </p>
        </div>

        <ContentSlider
          data={products}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          AddContent={
            selectedProduct && (
              <div className="list">
                {products
                  .filter((product) => product.name === selectedProduct)
                  .map((product) => (
                    <Item
                      key={product.name}
                      title={product.title}
                      description={product.description}
                      subDescription={product.subDescription}
                      esdesp1={product.subDesp1}
                      esdesp2={product.subDesp2}
                      esdesp3={product.subDesp3}
                      esdesp4={product.subDesp4}
                      bgImage={product.image}
                      listItems={product.listItems}
                      btnData={product.btnlink.map((link, idx) => {
                        let buttonText, className;
                        switch (idx) {
                          case 0:
                            buttonText = "Buy Now";
                            className =
                              "rounded-md w-full text-center bg-indigo-600 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600";
                            break;
                          case 1:
                            buttonText = "Download Demo";
                            className =
                              "rounded-md w-full text-center ml-3 bg-orange-600 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600";
                            break;
                          case 2:
                            buttonText = "Catalogue";
                            className =
                              "rounded-md w-full text-center ml-3 bg-cyan-600 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600";
                            break;
                          default:
                            buttonText = "Button" + (idx + 1);
                        }
                        return {
                          text: buttonText,
                          link: link,
                          className: className,
                        };
                      })}
                      learnLink={product.learnlink}
                    />
                  ))}
              </div>
            )
          }
        />
      </div>
    </section>
  );
});

export default MainProducts;

import React from 'react';
import '../../index.css'

export const Button = React.memo(({ label, type }) => {
  return (
    <>
      <button
        type={type}
        className="py-2 px-5 rounded-lg bg-primary text-primary-foreground shadow hover:bg-primary/90"
      >
        {label}
      </button>
    </>
  );
});

import React from "react";
import Slidebard from "../../Components/ui/Slidebard";

const data = [
  {
    name: "EasyOffice",
    link: "https://www.youtube.com/embed/gsjYmfKu77A?si=unXLJxPmQOTVGpSP",
  },
  {
    name: "EasyGst",
    link: "https://www.youtube.com/embed/Oim0RWXvQ7A",
  },
  {
    name: "EasyAcc",
    link: "https://www.youtube.com/embed/b_ecnPOgI4M",
  },
  {
    name: "EasyTax",
    link: "https://www.youtube.com/embed/9V3rhLdZ-4I",
  },
  {
    name: "EasyAudit",
    link: "https://www.youtube.com/embed/2g0iiN8La04",
  },
  {
    name: "EasyTds",
    link: "https://www.youtube.com/embed/WXD3zlS4Orc?si=AIrb13fkdAmxHv7U",
  },
  {
    name: "EasyCma",
    link: "https://www.youtube.com/embed/Co12EJfRjsg?si=S5_u4jFpzeCi6xcl",
  },
];

const TutorialVideo = React.memo(() => {
  return (
    <>
      <div className="py-10 flex justify-center relative sm:mt-0 sm:px-20">
        <div className="w-full max-w-7xl">
          <div className="text-center">
            <p className="text-4xl text-black font-bold">Tutorial Video</p>
          </div>
          <Slidebard
            MainContent={data.map((item, index) => (
              <div className="my-4">
                {" "}
                <div key={index} className="">
                  <div className="flex flex-col items-center">
                    <iframe
                      title="Video's"
                      src={item.link}
                      frameborder="0"
                      className=" shadow-xl p-4 rounded"
                    ></iframe>
                    <p className="text-xl font-semibold text-black">
                      {item.name}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          />
        </div>
      </div>
    </>
  );
});

export default TutorialVideo;

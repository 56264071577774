import React from 'react'
// import Slidebard from '../../Components/ui/Slidebard';
import c1 from '../../Images/ClientImg/1.png'
import c2 from '../../Images/ClientImg/2.png'
import c3 from '../../Images/ClientImg/3.png'
import c4 from '../../Images/ClientImg/4.png'
import c5 from '../../Images/ClientImg/5.png'
import c6 from '../../Images/ClientImg/6.png'
import c7 from '../../Images/ClientImg/7.png'
import Slider from 'react-slick'


const Slidebard = React.memo(({ MainContent }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,

    // They Comment value for Auto Scroll
    // autoplay: true,
    // autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-white w-full p-7 sm:px-14 overflow-hidden">
      <Slider {...settings}>
        {MainContent.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </Slider>
    </div>
  );
});



const data = [
  {
    name: "EasyOffice",
    link: c1,
  },
  {
    name: "EasyGst",
    link: c2,
  },
  {
    name: "EasyAcc",
    link: c3,
  },
  {
    name: "EasyTax",
    link: c4,
  },
  {
    name: "EasyAudit",
    link: c5,
  },
  {
    name: "EasyTds",
    link: c6,
  },
  {
    name: "EasyCma",
    link: c7,
  },
];

const Client = () => {
   return (
     <>
       <div className="py-10 flex justify-center sm:px-20">
         <div className='w-full max-w-7xl'>
           <div className="text-center">
             <p className="text-4xl text-black font-bold">
               Our Esteemed Clientele
             </p>
           </div>
           <Slidebard
             MainContent={data.map((item, index) => (
               <div className="my-4">
                 {" "}
                 <div key={index} className="">
                   <div className="flex flex-col items-center h-[110px]">
                     <img
                       alt="Products"
                       src={item.link}
                       frameborder="0"
                       className="rounded px-3 h-4/5"
                     />
                   </div>
                 </div>
               </div>
             ))}
           />
         </div>
       </div>
     </>
   );
}

export default Client;
import React from "react";

const FAQContent = React.memo(
  ({ data, AddContent, selectedProduct, setSelectedProduct }) => {
    const handleProductClick = (productName) => {
      setSelectedProduct(productName);
    };

    return (
      <section className="mt-5">
        <div className="sm:grid grid-cols-2 sm:grid-cols-5 max-sm:px-3 rounded-xl mx-3 py-2 sm:px-10">
          {data.map((product) => (
            <div>
              <div
                key={product.name}
                className={`item cursor-pointer rounded-s-lg ${selectedProduct === product.name ? "text-blue-600" : "text-black"} group py-2 text-2xl max-sm:text-lg font-bold flex w-full justify-center items-center hover:scale-105 transition-transform duration-300 ease-in-out ${
                  selectedProduct === product.name && "active"
                }`}
                onClick={() => handleProductClick(product.name)}
                style={{
                  backgroundColor:
                    selectedProduct === product.name ? "#ffffff" : "",
                  transform:
                    selectedProduct === product.name ? "scale(1.10)" : "",
                }}
              >
                {product.title}
                  </div>
                  <div className={`justify-center duration-150 ${selectedProduct === product.name ? "flex" : "hidden"}`}>
                      <div className="h-6 bg-blue-600 w-1"></div>
                  </div>
            </div>
          ))}
        </div>

        <div>{AddContent}</div>
      </section>
    );
  }
);

export default FAQContent;

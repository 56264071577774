import React from 'react'

const Extra = () => {
  return (
    <>
      <div className="flex justify-center bg-gray-100">
        <div className="max-w-7xl text-center py-9">
          <p className="mb-0 text-4xl text-black pb-7">
            Streamline your tax filing process with <br />
            EasyOffice - The complete taxation software!
          </p>
          <p className="mb-0 text-4xl text-black">
            Join with Thousands of Happy CA’s & Tax Professionals <br />
            Who believes in EasyOFFICE Software
          </p>
          <button className="mt-7 bg-black text-white px-7 sm:px-20 rounded-full font-bold text-3xl py-2 sm:py-6 hover:-translate-y-1 duration-150">
            <a
              href="https://www.easyofficesoftware.com/public/demo/demo/EasyOfficeDemoSetup.exe"
              target="_blank"
              rel="noreferrer"
            >
              Download Demo
            </a>
          </button>
        </div>
      </div>
    </>
  );
}

export default Extra
// import Rahul from "../../Images/Person/male1.jpg";
// import Rishabh from "../../Images/Person/male2.jpg";
// import Aman from "../../Images/Person/male3.jpg";
// import Vardhini from "../../Images/Person/female1.jpg";

const reviews = [
  {
    id: 1,
    name: "CA Neerav Agarwal",
    job: "NEERAV AGARWAL & ASSOCIATES",
    text: "“I am using EasyOFFICE software since 2011, it is so user-friendly software, and the technical support from the vendor is USP for Electrocom. Never faced any discomfort in software or service matter.”",
  },
  {
    id: 2,
    name: "Rajesh Daryani",
    job: "R.V. Daryani & Co.",
    text: "“I am using EasyOFFICE software since 9 years. I am very satisfied with Electrocom software.”",
  },
  {
    id: 3,
    name: "CA Purshotam S. Rathi",
    job: "P.S. Rathi & Co.",
    text: "“Working smoothly for the last 15 years and satisfied completely with the service and updation. Very User-friendly software and qualified staff.”",
  },
  {
    id: 4,
    name: "Dipen M Shah",
    job: "Bony Shah & Co.  ",
    text: "“Very Nice & User friendly software. Support is also very satisfactory from backend, fast solution for each & every problems.”",
  },
  {
    id: 5,
    name: "CA Ajit Kantilal Shah",
    job: "A.K. Shah & Associates  ",
    text: "“Quick Support, Easy to use and update the software and runs well on the firm system.”",
  },
  {
    id: 6,
    name: "CA Nilesh K Agarwal",
    job: "Nilesh K. Agarwal & Co",
    text: "“1. User-friendly Software2. Prompt Support from Company3. Prompt Updation of Software”",
  },
  {
    id: 7,
    name: "Hiren Madhubhai Ramani",
    job: "V.M. Ramani & Co",
    text: "“We have been working with Electrocom since last 15 years. They provide quality service & expertise for our needs. Everytime we contact for any technical assistance everyone speaks is so knowledgeable.”",
  },
  {
    id: 8,
    name: "Ghanshyam M Vachhani",
    job: "  Tax Advocate",
    text: "“We are using EasyOFFICE Software for Income Tax returns since 2004. We are extremely satisfied with their software & providing anytime assistance for using their software to smooth day to day practice”",
  },
  {
    id: 9,
    name: "CA Ronak Thakkar",
    job: "R. Thakkar & Associates  ",
    text: "“EasyOFFICE is quite easy just like its name. We use Income Tax and TDS, very well featured with all online support.”",
  },
  {
    id: 10,
    name: "CA Neerav Agarwal",
    job: "NEERAV AGARWAL & ASSOCIATES",
    text: "“I am using EasyOFFICE software since 2011, it is so user-friendly software, and the technical support from the vendor is USP for Electrocom. Never faced any discomfort in software or service matter.”",
  },
];
  
  export default reviews;


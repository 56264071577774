import easyOfficeIcon from "../../Images/Products/easyOffice.webp";
import easyGstIcon from "../../Images/Products/easyGst.webp";
import easyAccIcon from "../../Images/Products/easyAcc.webp";
import easyTaxIcon from "../../Images/Products/easyTax.webp";
import easyAuditIcon from "../../Images/Products/easyAudit.webp";
import easyTdsIcon from "../../Images/Products/easyTds.webp";
import easyCmaIcon from "../../Images/Products/EasyCma.webp";
import t1 from "../../Images/ModuleProduct/t1.png";
import t2 from "../../Images/ModuleProduct/t2.png";
import t3 from "../../Images/ModuleProduct/t3.png";
import t4 from "../../Images/ModuleProduct/t4.png";
import t5 from "../../Images/ModuleProduct/t5.png";
import t6 from "../../Images/ModuleProduct/t6.png";

const cardData = [
  {
    title: "EasyOFFICE",
    imageUrl: easyOfficeIcon,
    imgIcons: [t1, t2, t3, t4, t5, t6],
    content: [
      "Income Tax Software",
      "TDS & TCS Return Software",
      "Audit e-filing Software",
      "Balance Sheet Software",
      "CMA Software",
      "Office Management Utilities",
    ],
  },
  {
    title: "EasyGST",
    imageUrl: easyGstIcon,
    imgIcons: [t1, t2, t3, t4, t5, t6],
    content: [
      "Comprehensive Dashboard",
      "GST Returns e-filing",
      "Reconciliation with Accounts",
      "Annual Return & Audit Report",
      "Online Activities",
      "Multi-Angle Reports",
    ],
  },
  {
    title: "EasyACC",
    imageUrl: easyAccIcon,
    imgIcons: [t1, t2, t3, t4, t5, t6],
    content: [
      "Accounting Management",
      "Billing Management",
      "Inventory Management",
      "E- Invoice / E-way Bill",
      "Special Modules for Various Businesses",
      "Final Account Statements",
    ],
  },
  {
    title: "EasyTAX",
    imageUrl: easyTaxIcon,
    imgIcons: [t1, t2, t3, t4, t5, t6],
    content: [
      "Quick ITR Upload",
      "Income Tax Computation",
      "JSON / EXCEL Data Import",
      "AIS, TIS, 26AS Direct Import",
      "Multiangle Analysis Reports",
      "Online Activities",
    ],
  },
  {
    title: "EasyTDS",
    imageUrl: easyTdsIcon,
    imgIcons: [t1, t2, t3, t4, t5, t6],
    content: [
      "TDS & TCS Returns",
      "Correction Returns",
      "Generate TDS Certificates",
      "Prediction Analysis",
      "Import / Export facility",
      "Bulk PAN Verification",
    ],
  },
  {
    title: "EasyAUDIT",
    imageUrl: easyAuditIcon,
    imgIcons: [t1, t2, t3, t4, t5, t6],
    content: [
      "Form 3CA-3CD/3CB-3CD",
      "Tax Audit & Company Audit Reports",
      "Statements as per Schedule III",
      "Data Import from Tally & Excel",
      "CARO Report",
      "3CEB/29B/16B/10B/10BB",
    ],
  },
  {
    title: "EasyCMA",
    imageUrl: easyCmaIcon,
    imgIcons: [t1, t2, t3, t4, t5, t6],
    content: [
      "CMA Data Preparation",
      "MPBF statement",
      "Depreciation chart",
      "Financial Tools",
      "Multiple Formats of Report",
      "Office Management Utilities",
    ],
  },
];

export default cardData;

import React from 'react';
import './output.css';
import MyComponent from './feacture/Products/MyComponent';
import Header from './feacture/header/Header'
import Footer from './feacture/footer/Footer';
import Hero from "./feacture/hero/Hero";
import ContactForm from './feacture/contact/contactForm';
import About from './feacture/About/About';
import MainProducts from './feacture/MainProducts/mainProducts';
import InfoGraph from './feacture/Infograph/InfoGraph';
import TutorialVideo from './feacture/TutorialVideo/TutorialVideo';
import MainSecond from './feacture/Module/secModule/MainSecond';
import Ofm from './feacture/OMU/Ofm';
import Client from './feacture/EstimatedClient/Client';
import Review from './feacture/Review/review';
import Extra from './feacture/ExtraCompo/Extra';
import InterFace from './feacture/InterFAce/InterFace';
import NFAQ from './feacture/NFAQ/mainProducts';
import OfferCa from './feacture/OfferCA/OfferCa';
import Facility from './feacture/Facility/Facility';

function App() {
  
  return (
    <>
      <Header />
      <OfferCa />
      <Hero />
      <MyComponent />
      <MainProducts />
      <Facility />
      <InfoGraph />
      <MainSecond />
      <Ofm />
      <InterFace />
      <Extra />
      <About />
      <ContactForm />
      <Review />
      {/* <FAQ /> */}
      <NFAQ />
      <TutorialVideo />
      <Client />
      <Footer />
    </>
  );
}

export default App;

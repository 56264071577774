import React from "react";
import "./style.css";
import OfferImage from "../../Images/Offer.png"

const OfferCa = () => {
  return (
    <div className="mt-[65px] sm:mt-[83px]">
      <a
        href="https://ca.easyofficesoftware.com"
        target="_blank"
        rel="noreferrer"
      >
        <div class="mainBox">
          <img
            loading="lazy"
            src={OfferImage}
            class="ca-offer-avail-img"
            alt="offer"
          />
          <p className="mb-0">
            Special Offer For Practicing Chartered Accountant
          </p>
          <img
            loading="lazy"
            src={OfferImage}
            class="ca-offer-avail-img"
            alt="offer"
          />
        </div>
      </a>
    </div>
  );
};

export default OfferCa;

import React from 'react'
import moduleData from './Data/TaxData';
import module2Data from './Data/AuditData';
import module3Data from './Data/TdsData';
import module4Data from './Data/CmaData';
import SecModuleRight from './SecModuleRight';

const MainSecond = React.memo(() => {
  return (
    <>
      <div className="flex justify-center bg-white">
        <div className="w-full max-w-[1350px]">
          <div className="flex w-full flex-col items-center sm:px-8 pb-10">
            <div className="flex justify-center items-center w-full">
              <hr className="w-[70px] h-1 bg-[#4f95fd] mr-5" />
              <div className="text-center text-4xl font-extrabold my-3 mb-6">
                Income Tax Software
              </div>
              <hr className="w-[70px] h-1 bg-[#4f95fd] ml-5" />
            </div>
            <SecModuleRight dataStore={moduleData} />
            <div className="w-full flex justify-end mt-2">
              <a
                href="https://www.easyofficesoftware.com/incometaxsoftware"
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-end"
              >
                <p className="mb-0 mr-1 text-black font-bold text-lg">
                  Learn More
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-right mt-1"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="flex w-full flex-col items-center sm:px-8 pb-10">
            <div className="flex justify-center items-center w-full">
              <hr className="w-[70px] h-1 bg-[#4f95fd] mr-5" />
              <div className="text-center text-4xl font-extrabold my-3 mb-6">
                TDS & TCS Software
              </div>
              <hr className="w-[70px] h-1 bg-[#4f95fd] ml-5" />
            </div>
            <SecModuleRight dataStore={module3Data} />
            <div className="w-full flex justify-end mt-2">
              <a
                href="https://www.easyofficesoftware.com/tdssoftware"
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-end"
              >
                <p className="mb-0 mr-1 text-black font-bold text-lg">
                  Learn More
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-right mt-1"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="flex w-full flex-col items-center sm:px-8 pb-10">
            <div className="flex justify-center items-center w-full">
              <hr className="w-[70px] h-1 bg-[#4f95fd] mr-5" />
              <div className="text-center text-4xl font-extrabold my-3 mb-6">
                Audit e-filing & Balance Sheet
              </div>
              <hr className="w-[70px] h-1 bg-[#4f95fd] ml-5" />
            </div>
            <SecModuleRight dataStore={module2Data} />
            <div className="w-full flex justify-end mt-2">
              <a
                href="https://www.easyofficesoftware.com/auditsoftware"
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-end"
              >
                <p className="mb-0 mr-1 text-black font-bold text-lg">
                  Learn More
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-right mt-1"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="flex w-full flex-col items-center sm:px-8 pb-10">
            <div className="flex justify-center items-center w-full">
              <hr className="w-[70px] h-1 bg-[#4f95fd] mr-5" />
              <div className="text-center text-4xl font-extrabold my-3 mb-6">
                CMA Data Preparation
              </div>
              <hr className="w-[70px] h-1 bg-[#4f95fd] ml-5" />
            </div>
            <SecModuleRight dataStore={module4Data} />
            <div className="w-full flex justify-end mt-2">
              <a
                href="https://www.easyofficesoftware.com/cmasoftware"
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-end"
              >
                <p className="mb-0 mr-1 text-black font-bold text-lg">
                  Learn More
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-arrow-right mt-1"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default MainSecond;
import React, { useState, useEffect } from "react";
import people from "./data";
import './style.css'
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Review = React.memo(() => {
  const [index, setIndex] = useState(0);
  const {  name, job, text } = people[index];
  const maxIndex= people.length - 1;

  const checkNumber = (number) => {
    if (number > maxIndex) {
      return 0;
    }
    if (number < 0) {
      return maxIndex;
    }
    return number;
  };

  const nextPerson = () => {
    setIndex((index) => {
      let newIndex = index + 1;
      return checkNumber(newIndex);
    });
  };

  const prevPerson = () => {
    setIndex((index) => {
      let newIndex = index - 1;
      return checkNumber(newIndex);
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextPerson();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [index]);
  

  return (
    <>
      <div className="bg-white py-12 flex justify-center">
        <div className="max-w-7xl">
          <p className="text-center text-4xl text-black font-extrabold">
            Our Happy Customer
          </p>
          <div className="sm:px-[200px]">
            <article className="review">
              {/* <div className="img-container">
        <img src={image} alt={name} className="person-img" />
        <span className="quote-icon">
          <FaQuoteRight />
        </span>
      </div> */}
              <h4 className="author">{name}</h4>
              <p className="job">{job}</p>
              <p className="info">{text}</p>
              <div className="button-container">
                <button className="prev-btn" onClick={prevPerson}>
                  <FaChevronLeft />
                </button>
                <button className="next-btn" onClick={nextPerson}>
                  <FaChevronRight />
                </button>
              </div>
              
            </article>
          </div>
        </div>
      </div>
    </>
  );
});

export default Review;

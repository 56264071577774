import t1 from "../../../../Images/ModuleProduct/t1.png";
import t2 from "../../../../Images/ModuleProduct/t2.png";
import t3 from "../../../../Images/ModuleProduct/t3.png";
import t4 from "../../../../Images/ModuleProduct/t4.png";
import t5 from "../../../../Images/ModuleProduct/t5.png";
import t6 from "../../../../Images/ModuleProduct/t6.png";
import audit1 from "../../../../Images/ModuleProduct/audit/a1.webp";
import audit2 from "../../../../Images/ModuleProduct/audit/a2.webp";
import audit3 from "../../../../Images/ModuleProduct/audit/a3.webp";
import audit4 from "../../../../Images/ModuleProduct/audit/a4.webp";


const module2Data = [
  {
    name: "E-Audit Report",
    back: audit1,
    img: [t1, t2, t3, t4, t5, t6],
    text: [
      "Form 3CA-3CD/3CB-3CD with annexure",
      "Form 3CEB/29B/16B/10B/10BB",
      "Auto generation of JSON file of Audit reports",
      "Direct upload of Audit Report",
      "Tax Audit & Company Audit report",
      "CARO report",
    ],
  },
  {
    name: "Balance sheet preparation",
    back: audit2,
    img: [t1, t2, t3, t4, t5, t6],
    text: [
      "Annual Statements as per Schedule III",
      "Default standart formats of Balance sheet",
      "Data Import from Tally & Excel",
      "Depreciation Chart",
      "Export data in EasyTAX",
      "Preparation of final accounts",
    ],
  },
  {
    name: "Import facility",
    back: audit3,
    img: [t1, t2, t3, t4, t5, t6],
    text: [
      "Tally/ SDF/ DBF/ Text File",
      "Excel file format in trial balance",
      "XML/ JSON for Audit Forms",
      "Import TDS Details in 3CD",
      "Index Cost of Acquisition & Improvement",
      "Carry forward Previous year data",
    ],
  },
  {
    name: "Other features",
    back: audit4,
    img: [t1, t2, t3, t4, t5],
    text: [
      "Direct & Reports is durations",
      "Cash Flow & Fund flow",
      "Direct entries in final accounts format",
      "Auto Numbering Annexures and Notes to Account",
      "Comparative summary of current and previous year’s Financial Data",
    ],
  },
  
];

export default module2Data;

// import EasyOfficeImage from "../../Images/ProductBG/es5.jpg";
// import EasyGSTImage from "../../Images/ProductBG/g1.jpg";
// import EasyACCImage from "../../Images/ProductBG/as1.jpg";

let Products = [
  {
    name: "EasyOFFICE",
    title: "EasyOFFICE",
    description: `A Complete Taxation Software is a comprehensive solution that encompasses a range of modules, including Income Tax Software, TDS Software, Audit Software, CMA Data Software, and Office Management Utilities. The software is designed to cater to the needs of Tax Professionals and Chartered Accountants.`,
    subDescription: `The software's tax calculation & Advance online tools are the key features that simplify the tax preparation and return filing process, ensuring accuracy and compliance with the latest regulations. With its robust features and user-friendly interface, EasyOFFICE software is a preferred solution for Tax Professionals and it’s widely used across India. EasyOFFICE software can be downloaded free of cost.`,
    subDesp1: `EasyOFFICE Tax return filing software is exclusively designed and developed as per the system specified by the Income Tax Department of India, Protean and TRACES.`,
    subDesp2: `EasyOFFICE India’s Top Tax Return filing software also offers Multiple other features like Back up, Restore, EasyForm, MyInvoice, User Data Access Management, XML / JSON file import & many more. Calculating your income and tax liability, self-assessment tax, advance tax, Arrears, 26AS / AIS / TIS data direct Import and interest as per sections 234A, 234B, 234C & 234F has been made simple with EasyTAX software. All such features, Accuracy & user-friendly operation makes EasyOFFICE as Best Income Tax Software among tax professionals in India.`,
    subDesp3: `EasyAUDIT software incorporates various forms such as 3CA, 3CB, 3CD, 3CEB, 29B, 10B allows the preparation of audit reports & account statements as per Tax Audit and Company audit Schedules. EasyAudit is the Preferred choice among CA’s for audit report efiling. Managing TDS quarterly e-Return for 24Q, 26Q, 27Q, 27EQ, TDS Correction, Data Import options, Interest calculation, Online data automation tools & Multiple analysis reports makes it the Best TDS Return filing software. EasyCMA is the best software for preparing and calculating CMA Data.`,
    subDesp4: `The software's return filing capability is a key feature that streamlines the tax preparation and filing process, ensuring accuracy and compliance with the latest regulations. With its robust features and Powerful user interface, Electrocom's EasyOFFICE - A Complete Taxation Software is a trusted solution and valuable asset for professionals seeking to optimize their tax preparation & filing processes with ease.EasyOffice Software demo can be downloaded free of cost.`,

    image: "https://www.youtube.com/embed/gsjYmfKu77A",
    link: "https://www.easyofficesoftware.com/bank_details",
    listItems: [
      "IncomeTax Software",
      "TDS Return Software",
      "Audit e-filing Software",
      " Balance Sheet Software",
      "CMA Software",
      " Office Utilities",
    ],
    btnlink: [
      "https://www.easyofficesoftware.com/bank_details",
      "https://www.easyofficesoftware.com/public/demo/demo/EasyOfficeDemoSetup.exe",
      "https://www.easyofficesoftware.com/public/catalogue/EasyOFFICE-CATALOGUE.pdf",
    ],
    learnlink: "https://www.easyofficesoftware.com/easyofficesoftware",
  },
  {
    name: "EasyGST",
    title: "EasyGST",
    description: `EasyGST software is the Best & One-stop solution for all your GST compliances. The software is highly feature-rich, innovative & user-friendly making it an ideal solution for Tax Professionals & businesses.`,
    subDescription: `With features such as e-payment of taxes, auto intelligence ITC match/mismatch, Analysis report generation, Account data reconciliation with GSTR 2A and GSTR 2B, Online data automation with accuracy and much more. EasyGST software is designed to simplify the return e-filing process resulting in GST related workflow quick and easy for businesses.`,
    subDesp1: `EasyGST software supports direct uploading of GST returns, reconciliation module for GSTR 2A, GSTR 2B with accounts, MIS reports and analysis, data backup & restore, verifying bulk GSTIN and simplifying GST management with the comprehensive Dashboard. It further helps to track ITC (Input Tax Credit) match/mismatch for invoice reconciliation. It prepares & file GST returns such as GSTR 1, GSTR 3B, GSTR 4, GST CMP 08, GSTR 7, GSTR 9, GSTR 9C, etc. with ease`,
    subDesp2: `EasyGST return filing software provides a single-window dashboard design with access to all the necessary features and tax filing capabilities with easy and user-friendly operations. This return filing software is exclusively designed and developed as per GST guidelines.`,
    image: "https://www.youtube.com/embed/Oim0RWXvQ7A",
    link: "https://www.easyofficesoftware.com/bank_details",
    listItems: [
      "Single Window dashboard",
      "Import from Tally & Excel",
      "E-Invoice & E-way Bill",
      "Online Activities",
      "Return Statisticse",
      "Verify Bulk GSTIN",
    ],
    btnlink: [
      "https://www.easyofficesoftware.com/bank_details",
      "https://www.easyofficesoftware.com/public/demo/demo/EasyGSTDemoSetup.exe",
      "https://www.easyofficesoftware.com/public/catalogue/EasyGST-CATALOGUE.pdf",
    ],
    learnlink: "https://www.easyofficesoftware.com/easygst",
  },
  {
    name: "EasyACC",
    title: "EasyACC",
    description: `EasyACC is a complete, GST-ready financial accounting software that is designed to meet the needs of small and medium businesses, traders, retailers, dealers, distributors and manufacturing units. EasyACC software is an ideal solution for businesses for Inventory and Invoicing Management.`,
    subDescription: `With its user-friendly advanced features, EasyACC Software is an ideal solution for businesses looking to simplify their billings, cheque/cash management, payment reminders, orders and other financial operations. EasyACC Software is available with special modules for the Textile industry, Garment industry, Engineering units, Job works, Agency business and Travel agents.`,
    subDesp1: `EasyACC is the Best Accounting software for growing Businesses. EasyACC Software is available with special modules for the Textile industry, Garment industry, Engineering units, Job works, Agency business and Travel agents. The software's invoicing & inventory management capabilities provide a simple and efficient way to manage sales & stock, while its annual financial statement feature provides a comprehensive view of a business's financial performance. EasyACC Software demo can be downloaded free of cost.`,
    subDesp2: `EasyACC software's account book, bank / cash / JV / voucher and debit note / credit note features provide a complete view of all financial transactions, enabling businesses to effectively manage their financial operations. Additionally, the software's e-way bill generation, GST reports & analysis, purchase management, inventory management, bank management and cash management capabilities provide a comprehensive solution for businesses looking to optimize their financial operations and stay compliant with the latest regulations. To Keep our customers up-dated with current market needs we regularly update & add new features to the software. EasyACC is the best Billing Software for Small Businesses in India.`,
    image: "https://www.youtube.com/embed/b_ecnPOgI4M",
    link: "https://www.easyofficesoftware.com/bank_details",
    listItems: [
      " Invoicing",
      "Inventory",
      "Account Books",
      "Bank/Cash/JV/Ledger",
      "Debit note/Credit note",
      "Cash Management",
    ],
    btnlink: [
      "https://www.easyofficesoftware.com/bank_details",

      "https://www.easyofficesoftware.com/public/demo/demo/EasyAccDemoSetup.exe",
      "https://www.easyofficesoftware.com/public/catalogue/EasyACC-CATALOGUE.jpg",
    ],
    learnlink: "https://www.easyofficesoftware.com/easyacc",
  },
];

export default Products;

